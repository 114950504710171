html, body {
  height: 100%;
  width: 100%;
  color: #000;
}
.main {
  padding: 30px 20%;
  background-image: url('https://jiatan-1254247596.file.myqcloud.com/ed7ab58e75524234ebcc1cc06edc9c5d/5f6c40f7/2020/9/24/4a296124ff0749b0b0244ce24aea7440_large.jpeg');
  background-repeat:no-repeat;
  background-size:100% 100%;
}
.header img {
  width: 50px;
  height: 50px;
}
.content {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 0 10%;
  font-weight: 500;
}
.content_left {
  margin-top: -300px;
}
.split {
  height: 5px;
  width: 100%;
  background: #7e8285;
  margin-bottom: 10px;
}
.title {
  font-size: 40px;
  text-align: center;
}
.content_bottom {
  display: flex;
  margin-top: 50px;
  margin-bottom: 200px;
}
.bottom_card {
  width: 25%;
  font-weight: 500;
  text-align: center;
  padding: 30px;
}
.bottom_card img {
  height: 200px;
}
.bottom_card :nth-child(2) {
  font-size: 22px;
}
.bottom_card :nth-child(3) {
  font-size: 16px;
  color: #7e8285;
}
.card {
  display: flex;
  padding: 30px;
  position: relative;
}
.card_img {
  display: flex;
  padding: 30px 130px 30px 100px;
  align-items: center;
  justify-content: center;
}
.explain {
  color: #7e8285;
  font-weight: 500;
  font-size: 18px;
  position: relative;
}
.tab {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
.tab_top, .tab-bottom {
  display: flex;
  margin-top: 7px;
}
.sign {
  width: 12px;
  height: 12px;
  padding: 0;
  background: #f5f5f5;
  margin-left: 7px;
}
.tags {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -30px;
}
.tags_left {
  height: 5px;
  width: 70px;
  background: #6c6c6c;
}
.tags_right {
  height: 5px;
  width: 20px;
  background: #009E96;
  margin-left: 10px;
}
.identifier {
  text-align: center;
  margin-top: 50px;
  color: #7e8285;
}
.linkTags {
  position: relative;
  float: right;
}
